import ApplicationsList from "./components/application/ApplicationsList";

function App() {
  return (
    <div className="App">
      <ApplicationsList/>
    </div>
  );
}

export default App;
